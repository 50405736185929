import { TRun } from '../../common/types/run';

export enum TRunSort {
  DATE = 'date',
  RIDER = 'rider',
  NAME = 'description',
  MASS = 'mass',
  ROLLING = 'rolling',
  POWER_SLOPE = 'power_slope',
  WHEEL_SIZE = 'wheel_size',
  CP_CORR = 'cp_corr',
  XW_SLOPE = 'xw_slope',
  PROBE = 'probe',
}

export type TDefaultFilter = {
  riders: string[];
  rider_id: string[];
  hidden: boolean | null;
  run_ts: { startDate: string | null; endDate: string | null; dateEnabled: boolean | null };
};
export type TRunsContext = {
  runs: TRun[];
  showHidden: boolean;
  unprocessedRuns: any[];
  runsById: Record<string, TRun>;
  selectedRuns: string[];
  mergedSelectedRuns: Record<string, any>;
  plottedRuns?: any[];
  isLoading: boolean;
  dataLoading: Set<string>;
  sortBy: TRunSort;
  drawers: TDrawers;
  lastModified?: string;
};

export enum RunsActionTypes {
  SET_DATA = 'SET_DATA',
  SET_ALL_RUNS = 'SET_ALL_RUNS',
  SET_RUNS_NEED_PROCESSING = 'SET_RUNS_NEED_PROCESSING',
  SET_EDITING_RUNS = 'SET_EDITING_RUNS',
  SET_PLOTTED_RUNS = 'SET_PLOTTED_RUNS',
  SET_DATA_LOADING = 'SET_DATA_LOADING',
  SET_SHOW_HIDDEN = 'SET_SHOW_HIDDEN',
  REFRESH_MERGED_RUN = 'REFRESH_MERGED_RUN',
  SET_FILTER_VARS = 'SET_FILTER_VARS',
  SET_ALL_RIDERS = 'SET_ALL_RIDERS',
  SET_IS_LOADING = 'SET_IS_LOADING',
  SORT_RUNS = 'SORT_RUNS',
  ADD_TO_DRAWER = 'ADD_TO_DRAWER',
  REMOVE_FROM_DRAWER = 'REMOVE_FROM_DRAWER',
  SET_LAST_MODIFIED = 'SET_LAST_MODIFIED',
}

export type TDrawers = Record<string, string[]>;
export type TDrawerPayload = { drawerName: string; id: string };
