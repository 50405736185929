import { Dispatch } from 'react';
import { ConfigActionTypes } from './_types';

export const setIsLoading = (dispatch: Dispatch<any>) => {
  return (payload = false): void => dispatch({ type: ConfigActionTypes.SET_IS_LOADING, payload });
};
export const setDataLoading = <T>(payload: T, dispatch: Dispatch<any>): void => {
  dispatch({ type: ConfigActionTypes.SET_DATA_LOADING, payload });
};
export const setConfigs = (dispatch: Dispatch<any>) => {
  return (payload: any[]): void => dispatch({ type: ConfigActionTypes.SET_ALL_CONFIGS, payload });
};
export const setNewConfigs = (dispatch: Dispatch<any>) => {
  return (payload: any[], hasChanges?: boolean): void =>
    dispatch({
      type: ConfigActionTypes.SET_NEW_CONFIGS,
      payload,
      hasChanges,
    });
};
export const setSelectedConfigIds = (dispatch: Dispatch<any>) => {
  return (payload: any | any[] = []): void => {
    dispatch({
      type: ConfigActionTypes.SET_SELECTED_CONFIGS,
      payload,
    });
  };
};
export const toggleSelectedConfigId = (dispatch: Dispatch<any>) => {
  return (payload: string | 'clearCfg' | 'clearCal'): void => {
    const type = (function (): ConfigActionTypes {
      switch (payload) {
        case 'clearCfg':
          return ConfigActionTypes.CLEAR_EDITING_CONFIG;
        default:
          return ConfigActionTypes.SET_EDITING_CONFIG;
      }
    })();

    dispatch({
      type: type,
      payload,
    });
  };
};

export const setRunsToAdd = (dispatch: Dispatch<any>) => {
  return (payload: string[]): void => dispatch({ type: ConfigActionTypes.SET_RUNS_TO_ADD, payload });
};
export const setRunsToRemove = (dispatch: Dispatch<any>) => {
  return (payload: string[]): void => dispatch({ type: ConfigActionTypes.SET_RUNS_TO_REMOVE, payload });
};
export const setLastModifiedConfig = (dispatch: Dispatch<any>) => {
  return (payload: string | number): void => dispatch({ type: ConfigActionTypes.SET_LAST_MODIFIED, payload });
};
