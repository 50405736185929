import { TConfig } from '../../common/types/config';

export const sortConfigs = (itemA: TConfig, itemB: TConfig): -1 | 1 =>
  itemA.run_ts && itemB.run_ts && new Date(itemA.run_ts) > new Date(itemB.run_ts) ? -1 : 1;

export const sortSessionConfigs = (configA: TConfig, configB: TConfig) => {
  if (configA.letter === 'DISC') return 1;
  if (configB.letter === 'DISC') return -1;
  return configA.letter.localeCompare(configB.letter);
};
