import { useGetAndDispatch, useSWRGet } from './getData';
import { PublicConfiguration } from 'swr/_internal';
import { requestWithAxios } from '../../utils/requestWithAxios';
import { TTestSession } from '../../common/types/session';
import { sortSessions } from '../../pages/Sessions/List/utils';
import { useProfileGet } from './profile';

export class SessionsFetcher {
  static get key() {
    return { endpoint: '/test-sessions', scope: 'with_runs' };
  }

  static fetcher = async (args: typeof SessionsFetcher.key) => {
    const { data } =
      (await requestWithAxios({
        ...args,
        verb: 'GET',
      })) || {};
    return data;
  };
}

// Hook to retrieve all sessions, using a dispatch store
export const useSessionsGet = (dispatch: any, swrOptions?: Partial<PublicConfiguration>) => {
  return useGetAndDispatch(dispatch)<TTestSession[]>(
    {
      ...SessionsFetcher.key,
      swrOptions,
    },
    sortSessions,
  );
};

// Hook to retrieve a single session using a get
export const useSessionGet = (sessionUuid: any) => {
  const { data: profile } = useProfileGet();
  return useSWRGet<TTestSession>({
    endpoint: '/test-sessions',
    scope: 'calculated_data_internal',
    id: sessionUuid,
    swrOptions: { revalidateOnFocus: true },
    teamId: profile.team_id,
  });
};
