import { useSWRGet } from '../../../../stores/ajax/getData';
import { useProfileGet } from '../../../../stores/ajax/profile';

interface TAjaxGetCalcDataList extends Record<string, any> {
  selectedIds?: string[];
  controller?: AbortController;
  sessionUuid?: string;
}

export function useSWRCalcDataList<T>({ controller, selectedRuns, sessionUuid }: TAjaxGetCalcDataList) {
  const { data: profile } = useProfileGet();
  return useSWRGet<T>({
    endpoint: sessionUuid ? '/test-sessions' : '/runs',
    scope: sessionUuid ? 'calculated_data_internal' : 'calculated_data_list',
    abortSignal: controller?.signal,
    id: sessionUuid ?? selectedRuns,
    swrOptions: { revalidateOnFocus: true },
    teamId: profile.team_id,
  });
}
