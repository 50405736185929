export const editRowClassName = (color: 'green' | 'blue' = 'green') =>
  `${color === 'blue' ? 'cr-border-blue-500 cr-bg-blue-50' : 'cr-border-green-500 cr-bg-green-50'} cr-opacity-1 cr-h-full cr-border-2 cr-border-solid cr-rounded-b-md `;

export const messageClassName = (color: 'green' | 'blue' = 'green', rounded: 't' | 'b' | 'all' | null = null) =>
  `${color === 'blue' ? 'cr-border-blue-500 cr-bg-blue-50' : 'cr-border-green-500 cr-bg-green-50'} cr-opacity-1 cr-border-2 cr-border-solid ${rounded ? 'cr-rounded-t-md' : ''} `;

export const editRowToggleClassName = (visible?: boolean) =>
  visible ? '' : ' cr-collapse cr-h-0 cr-overflow-hidden cr-opacity-0 cr-hidden';

export const hiddenClassName = (hidden?: boolean | null) => (hidden ? ' cr-text-gray-300 cr-bg-gray-100' : '');

export const isNextClassName = (isNext?: boolean) =>
  isNext
    ? ' cr-text-blue-500 cr-font-bold [&>td]:cr-border-b [&>td]:cr-border-b-blue-500 [&>td]:cr-bg-gradient-to-b [&>td]:cr-from-transparent [&>td]:cr-from-90% [&>td]:cr-to-blue-500 '
    : '';

export const liveSessionClassName = (isLive?: boolean) =>
  isLive ? ' cr-bg-green-500 cr-text-white' : ' cr-bg-blue-500 cr-text-white';

export const lastModifiedClassName = (lastModified?: boolean) => (lastModified ? ' cr-animate-fade-highlight' : '');
