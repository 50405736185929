import { componentKey } from '../../../../utils/componentKeyGen';
import { configTestSessionColumns } from './columns';
import { ListViewCell } from '../../../../components/ListView';
import { columnHiddenSmClass } from '../../../TeamView/menuColumns';
import IsNextFlag from './IsNextFlag';
import { CircularProgress } from '@mui/material';
import Row from '../../../../layouts/Row';
import { CallSplit, DoNotTouch, Lock } from '@mui/icons-material';
import { isEmpty } from 'lodash';
import SessionEllipsisButton from '../TestSessions/SessionEllipsisButton';
import { setEditedRuns } from '../../../../stores/runs/setters';
import { setRunsToAdd, setRunsToRemove } from '../../../../stores/configs/setters';
import { StreamlinesConfig } from '../../../../icons/StreamlinesConfig';
import { ListViewRow } from '../../../../components/ListView/ListViewRow';
import { useRuns, useSessionRuns } from '../../../../stores/runs/useRuns';
import { useParams } from 'react-router-dom';
import { useConfigs } from '../../../../stores/configs/useConfigs';
import { useExtraButtonsConfig } from '../ExtraButtons';
import CalibrationStatusWarning from './CalibrationStatusWarning';
import { isNextClassName, lastModifiedClassName } from '../../../../utils/tailwind';
import { RunList } from '../../../../common/classes/RunList';
import { defaultRun, TRun } from '../../../../common/types/run';
import { TConfig } from '../../../../common/types/config';
import { updateRuns } from '../../../../stores/ajax/updateRuns';
import { updateGroupRuns } from '../../../../stores/ajax/updateGroupRuns';

type Props<T> = {
  dataItem: T;
  className?: string;
  onClick?: CallableFunction;
  index: number;
  pathname: string;
  pathParent: string;
  handleCdaParamChange: CallableFunction;
  cdaParam: any;
};

export default function ReadRow<T extends { [key: string]: any }>({
  dataItem,
  className,
  onClick,
  index,
  pathname,
  pathParent,
  cdaParam,
}: Props<T>) {
  const { sessionUuid = '' } = useParams();
  const [, runsDispatch, refreshRuns] = useRuns();
  const [{ configsById, dataLoading, lastModified }, configsDispatch, refreshConfigs] = useConfigs();
  const SessionRuns = useSessionRuns(sessionUuid);
  const nextRunConfigs = SessionRuns.getNextRunConfigs();
  const extraButtons = useExtraButtonsConfig();
  const isNext = !!nextRunConfigs?.find(({ group_id }) => group_id === dataItem.id);

  const configRuns = new RunList<TRun & { configId: TConfig['id'] }>(SessionRuns.findConfigRuns(dataItem.id));

  return (
    <ListViewRow
      key={componentKey('tr-data', dataItem.id)}
      className={
        className +
        isNextClassName(isNext) +
        lastModifiedClassName(lastModified === dataItem.id) +
        ' cr-cursor-pointer hover:cr-bg-blue-300 hover:cr-text-blue-500'
      }
    >
      {configTestSessionColumns.map((column) => {
        switch (column.param) {
          case '_setNext':
            return (
              <ListViewCell
                key={componentKey('td-data', column.param, dataItem.id)}
                className={className + ' cr-w-[50px] cr-align-middle '}
                onClick={async () => {
                  if (isNext) return undefined;
                  if (configRuns.futureRuns.length) {
                    const runs = SessionRuns.swapRunNumber(SessionRuns.nextRunNumber, configRuns.futureRuns[0]);
                    await updateRuns({ runs });
                    refreshRuns();
                    refreshConfigs();
                  } else {
                    const newRun = defaultRun({
                      calibration: SessionRuns.lastCalibration,
                      description: dataItem.description,
                      hidden: false,
                      run_number: SessionRuns.nextRunNumber,
                    });

                    const runs = SessionRuns.cleanFutureRunNumber({
                      ...newRun,
                      configId: dataItem.id,
                    })();
                    const data = await updateRuns({
                      verb: 'POST',
                      runs: [newRun],
                    });

                    if (data) {
                      await updateRuns({ runs });
                      await updateGroupRuns({
                        groupId: [dataItem.id],
                        addRuns: [newRun.id],
                        removeGroupRuns: [],
                      });
                      refreshRuns();
                      refreshConfigs();
                    }
                  }
                }}
              >
                <IsNextFlag
                  name={'next-config'}
                  id={dataItem.id}
                  selected={isNext}
                  disabled={!configRuns.futureRuns.length}
                />
              </ListViewCell>
            );
          case '_calibration': {
            const item: any = dataItem;
            return (
              <ListViewCell
                key={componentKey('td-data', column.param, dataItem.id)}
                className={className + ' cr-align-middle ' + columnHiddenSmClass}
              >
                {item.listWarning?.ERROR || item.listWarning?.WARNING || item.listWarning?.DIFFERENT_CALIBRATION ? (
                  <CalibrationStatusWarning dataItem={dataItem} />
                ) : null}
              </ListViewCell>
            );
          }
          case 'calibration_status': {
            return (
              <ListViewCell
                key={componentKey('td', column.param, dataItem.id)}
                onClick={onClick}
                className={columnHiddenSmClass + ' ' + className}
              >
                <Row columns={3} columnWidth={'min-content'}>
                  {dataItem.autocal_lock && <Lock />}
                  {!isEmpty(dataItem.autocal_branch) && <CallSplit sx={{ transform: 'scale(1, -1)' }} />}
                  {dataItem.autocal_skip && <DoNotTouch />}
                </Row>
              </ListViewCell>
            );
          }
          case 'edit':
            return (
              <ListViewCell
                key={componentKey('td-data', column.param, dataItem.id)}
                className={className + ' cr-w-[36px]'}
              >
                <SessionEllipsisButton
                  {...{
                    onEdit: (): void => {
                      setEditedRuns(dataItem.run_ids ?? [], runsDispatch);
                      setRunsToAdd(configsDispatch)([]);
                      setRunsToRemove(configsDispatch)([]);
                    },
                    moreButtons: extraButtons(dataItem as any),
                    item: dataItem,
                    index,
                    name: 'config',
                    title: 'Config Runs',
                    query: { sessionUuid },
                    pathParent,
                    pathname,
                  }}
                />
              </ListViewCell>
            );
          case 'baseline':
            return (
              <ListViewCell
                key={componentKey('td-data', column.param, dataItem.id)}
                onClick={onClick}
                className={className + ' ' + columnHiddenSmClass}
              >
                {dataItem.baseline ? configsById[dataItem.baseline]?.letter : ''}
              </ListViewCell>
            );
          case 'icon':
            return (
              <ListViewCell
                key={componentKey('td-data', column.param, dataItem.id)}
                onClick={onClick}
                className={className + ' cr-w-36 cr-align-middle ' + columnHiddenSmClass}
              >
                <div className={'cr-text-center'}>
                  {dataLoading.has(dataItem.id) ? <CircularProgress /> : <StreamlinesConfig />}
                </div>
              </ListViewCell>
            );
          case 'average':
            return (
              <ListViewCell
                key={componentKey('td-data', column.param, dataItem.id)}
                onClick={onClick}
                className={className}
              >
                {typeof dataItem.average?.['CdAXWnNB_xG3']?.[0] === 'number' ? (
                  <div>{dataItem.average[cdaParam][0].toFixed(3)}</div>
                ) : null}
              </ListViewCell>
            );
          case 'deltas':
            return (
              <ListViewCell
                key={componentKey('td-data', column.param, dataItem.id)}
                onClick={onClick}
                className={className}
              >
                {typeof dataItem.deltas?.['CdAXWnNB_xG3']?.[0] === 'number' ? (
                  <div
                    className={`${dataItem.deltas?.[cdaParam][0] <= 0 ? 'cr-font-bold cr-text-blue-500 ' : 'cr-text-red'}`}
                  >
                    {dataItem.deltas[cdaParam][0] >= 0 ? '+' : ''}
                    {dataItem.deltas[cdaParam][0] ? dataItem.deltas[cdaParam][0].toFixed(3) : null}
                  </div>
                ) : null}
              </ListViewCell>
            );
          default: {
            const item: any = dataItem;
            return (
              <ListViewCell
                key={componentKey('td-data', column.param, dataItem.id)}
                onClick={onClick}
                className={className}
              >
                {item[column.param]}
              </ListViewCell>
            );
          }
        }
      })}
    </ListViewRow>
  );
}
