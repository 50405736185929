import { ReactElement, useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useConfigs } from '../../../stores/configs/useConfigs';
import Column from '../../../layouts/Column';
import { useRuns } from '../../../stores/runs/useRuns';
import ListView, { ListViewBody } from '../../../components/ListView';
import { goTo } from './toggleListOnClick';
import { TRun } from '../../../common/types/run';
import RunListWarnings from './RunListWarnings';
import useGetCalcDataList from '../../../components/InteractivePlotter/data/fetching/useGetCalcDataList';
import HeaderRow from './TestSessionConfigs/HeaderRow';
import { configTestSessionColumns } from './TestSessionConfigs/columns';
import { componentKey } from '../../../utils/componentKeyGen';
import RunViewRow from './TestSessionConfigs/RunViewRow';
import ReadRow from './TestSessionConfigs/ReadRow';
import { sortSessionConfigs } from '../../../utils/state/configs';

export default function SessionConfigsList(): ReactElement {
  const pathParent = 'configs';
  const { sessionUuid = '' } = useParams();

  const [{ configsByTestSessionId, configsById }] = useConfigs();
  const [{ runsById }] = useRuns();
  const [calcDataList] = useGetCalcDataList(sessionUuid, { only: 'configs' });
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { configUuid } = useParams();
  const [openedConfigs, setOpenedConfigs] = useState<(string | number)[]>(configUuid ? [configUuid] : []);

  const sessionConfigs = configsByTestSessionId[sessionUuid];

  const ConfigRunList = useMemo(
    () =>
      sessionConfigs?.reduce(
        (
          acc: (TRun & {
            parent?: string;
            hasDifferentCalibration: boolean;
          })[],
          config,
        ) => {
          if (runsById) {
            //we only need one run from each config to compare
            let hasDifferentCalibration = false;
            let firstRunId: null | string = null;
            config.run_ids?.forEach((runId, _index, array) => {
              if (!runsById[runId]?.hidden) {
                if (!firstRunId) {
                  firstRunId = runId;
                }
                const initialRunId = array[0];
                hasDifferentCalibration = runsById[runId]?.calibration !== runsById[initialRunId]?.calibration;
              }
            });

            if (firstRunId && configsById[config.baseline ?? '']) {
              acc.push({
                ...runsById[firstRunId],
                letter: config.letter,
                parent: configsById[config.baseline ?? '']?.letter,
                hasDifferentCalibration,
              });
            } else if (firstRunId) {
              acc.push({
                ...runsById[firstRunId],
                letter: config.letter,
                parent: 'first',
                hasDifferentCalibration,
              });
            }
          }
          return acc;
        },
        [],
      ),
    [sessionConfigs, runsById, configsById],
  );
  const runsWithListWarnings = RunListWarnings<TRun & { parent?: string }>(ConfigRunList);
  const printList = sessionConfigs?.sort(sortSessionConfigs).map((config, index) =>
    runsWithListWarnings[index]
      ? {
          ...config,
          average: calcDataList ? calcDataList[config.id]?.average : {},
          deltas: calcDataList ? calcDataList[config.id]?.deltas ?? {} : {},
          listWarning: runsWithListWarnings[index].listWarning,
        }
      : {
          ...config,
          average: calcDataList ? calcDataList[config.id]?.average : {},
          deltas: calcDataList ? calcDataList[config.id]?.deltas ?? {} : {},
        },
  );
  const isToggled = (itemId: string | number) => openedConfigs.includes(itemId);
  const [cdaParam, setCdaParam] = useState<'CdAXWnNB_xG3' | 'CdA_xG3'>('CdAXWnNB_xG3');

  const handleCdaParamChange = useCallback((value: 'CdAXWnNB_xG3' | 'CdA_xG3') => setCdaParam(value), []);
  return (
    <>
      {sessionUuid ? (
        <Column data-testid={'session-configs'} className={'cr-overflow-auto'}>
          {printList?.length ? (
            <>
              <ListView columns={configTestSessionColumns} classes={{ header: 'cr-w-[calc(100%_-_38px)]' }}>
                <HeaderRow />
                <ListViewBody
                  data={printList}
                  onClick={(dataItem) => {
                    setOpenedConfigs((current) => {
                      const i = current.indexOf(dataItem.id);
                      return i > -1 ? [...current.slice(0, i), ...current.slice(i + 1)] : [...current, dataItem.id];
                    });
                    goTo(navigate)({
                      item: dataItem,
                      pathname,
                      parentPath: `${pathParent}`,
                      stepBack: true,
                    })();
                  }}
                  className={'cr-border-b cr-border-solid cr-border-b-blue-900 [&>td]:cr-text-left'}
                  render={({ dataItem, index, className, onClick }) => [
                    <ReadRow
                      key={componentKey('tr-config-data', dataItem.id)}
                      {...{
                        dataItem,
                        index,
                        onClick,
                        className: className + (isToggled(dataItem.id) ? ' cr-bg-blue-200 ' : ''),
                        pathname,
                        pathParent,
                        handleCdaParamChange,
                        cdaParam,
                      }}
                    />,
                    <RunViewRow
                      key={componentKey('tr-runs-data', dataItem.id)}
                      {...{
                        show: isToggled(dataItem.id),
                        itemId: dataItem.id,
                        index,
                        className,
                        pathParent,
                        handleCdaParamChange,
                        cdaParam,
                      }}
                    />,
                  ]}
                />
              </ListView>
            </>
          ) : (
            'No configs in this session'
          )}
        </Column>
      ) : null}
    </>
  );
}
