/** @jsxImportSource @emotion/react */
import { Fragment, MouseEvent, PropsWithChildren, ReactElement, ReactNode, useCallback, useState } from 'react';
import { Menu, MenuItem, Tooltip } from '@mui/material';
import { css } from '@emotion/react';
import { ListViewItemExtended } from '../types';

export default function Notes<T>(
  props: PropsWithChildren<{
    item: ListViewItemExtended<T>;
    renderTooltip?: () => ReactNode;
    disabled?: boolean;
    hoverDisabled?: boolean;
  }>,
): ReactElement | null {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = useCallback((event: MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback((): void => {
    setTimeout(() => {
      setAnchorEl(null);
    }, 50);
  }, [anchorEl]);

  return props.item.notes || props.children ? (
    <Fragment>
      <Tooltip data-testid={`&notes-${props.item.id}`} title="Item Notes" placement={'top-start'}>
        <div
          className={props.hoverDisabled ? 'cr-cursor-pointer' : undefined}
          onClick={!props.disabled ? handleClick : undefined}
          onMouseOver={!props.disabled && !props.hoverDisabled ? handleClick : undefined}
          css={css({
            zIndex: 39,
            top: props.children ? undefined : '0.375rem',
            position: props.children ? 'initial' : 'absolute',
            width: '10px',
            height: '10px',
            right: props.children ? undefined : '0.375rem',
            background: props.children ? undefined : 'linear-gradient(45deg, rgba(0,0,0,0) 50%, #0E5885 50%)',
          })}
        >
          {props.children}
        </div>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id={`notes-${props.item.id}`}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        MenuListProps={{ onMouseLeave: handleClose }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'center' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'center' }}
      >
        {props.renderTooltip ? props.renderTooltip() : <MenuItem>{props.item.notes}</MenuItem>}
      </Menu>
    </Fragment>
  ) : null;
}
