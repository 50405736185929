import { ButtonProps, IconButton, Menu, MenuItem, Divider } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import { ReactElement, useState, MouseEvent, ReactNode } from 'react';
import { useActionGuard } from '../../ActionGuard/useActionGuard';

type ItemProps = {
  element: ReactNode;
  color?: ButtonProps['color'];
  disabled?: boolean;
  onClick?: ({ handleClose }: { handleClose: () => void }) => void;
  subMenu?: ItemProps[];
  warn?: string | null;
};
export type MoreButtonProps = {
  MenuItems: ItemProps[];
  label?: ItemProps['element'];
  className?: string;
  id: string | number;
};

export default function MoreButton(props: MoreButtonProps): ReactElement {
  const handleClick = (event: MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const { ActionGuard, toggle, on } = useActionGuard(false);

  return (
    <div data-testid={'more-btn'} className={'cr-w-36'}>
      {props.label ? (
        <>
          {/* eslint-disable-next-line react/jsx-no-undef */}
          <MenuItem className={'cr-justify-between'} onClick={handleClick}>
            {props.label}
            <MoreVert />
          </MenuItem>
          <Divider />
        </>
      ) : (
        <IconButton
          aria-label="more"
          size={'small'}
          id="long-button"
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
          color={'inherit'}
        >
          <MoreVert />
        </IconButton>
      )}

      <Menu
        id="long-menu"
        MenuListProps={{
          className: props.className,
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: {
            width: '38ch',
          },
        }}
      >
        {props.MenuItems.map(({ element, color, disabled, onClick, subMenu, warn }, index) =>
          subMenu ? (
            <MoreButton
              className={'cr-bg-blue-100'}
              key={`ext_btn_menu_item-${props.id}-${index}`}
              id={props.id}
              MenuItems={subMenu}
              label={element}
            />
          ) : (
            <MenuItem
              key={`ext_btn_menu_item-${props.id}-${index}`}
              color={color}
              disabled={disabled}
              onClick={() => {
                if (on) return;
                return warn ? toggle() : onClick?.({ handleClose });
              }}
            >
              {element}
              {warn && (
                <ActionGuard
                  message={warn}
                  onYes={() => {
                    onClick?.({ handleClose });
                  }}
                />
              )}
            </MenuItem>
          ),
        )}
      </Menu>
    </div>
  );
}
