import { componentKey } from '../../../../utils/componentKeyGen';
import { ListViewRow } from '../../../../components/ListView/ListViewRow';
import { runColumns } from './columns';
import { ListViewCell } from '../../../../components/ListView';
import MoreButton from '../../../../components/Buttons/MoreButton';
import { useExtraButtonsRuns } from '../ExtraButtons';
import { Fragment } from 'react';
import { columnHiddenMdClass } from '../../../TeamView/menuColumns';
import { CircularProgress } from '@mui/material';
import { StreamlinesRun } from '../../../../icons/StreamlinesRun';
import RunStatusColors from '../../../../components/RunStatus';
import { useRuns, useSessionRuns } from '../../../../stores/runs/useRuns';
import { TRun } from '../../../../common/types/run';
import { useParams } from 'react-router-dom';
import ListWarningsComponent, { ListWarning } from '../ListWarnings';
import formatDate from '../../../../utils/formatDate';
import Notes from '../../../../components/ListView/Notes';
import { hiddenClassName, isNextClassName, lastModifiedClassName } from '../../../../utils/tailwind';
import IsNextFlag from '../TestSessionConfigs/IsNextFlag';

type Props<T> = {
  dataItem: T;
  className?: string;
  onClick?: CallableFunction;
  index: number;
  hasNewRuns: boolean;
  handleCdaParamChange: CallableFunction;
  cdaParam: any;
};

export default function ReadRow<
  T extends TRun & { listWarning: ListWarning } & {
    average: Record<string, number[]>;
    deltas: (param: string) => number;
  },
>({ dataItem, className, onClick, cdaParam }: Props<T>) {
  const [{ dataLoading, lastModified }] = useRuns();
  const { sessionUuid } = useParams();
  const SessionRuns = useSessionRuns(sessionUuid);

  const extraButtons = useExtraButtonsRuns();
  const hasWarning = dataItem.listWarning?.ERROR || dataItem.listWarning?.WARNING;
  const isNextConfig = dataItem.run_number === SessionRuns.nextRunNumber;
  return (
    <Fragment key={componentKey('tr-data', dataItem.id)}>
      <ListViewRow
        className={
          className +
          isNextClassName(isNextConfig) +
          hiddenClassName(dataItem.hidden) +
          lastModifiedClassName(lastModified === dataItem.id)
        }
      >
        {runColumns.map((column) => {
          switch (column.param) {
            case 'edit':
              return (
                <ListViewCell
                  key={componentKey('td-data', column.param, dataItem.id)}
                  onClick={onClick}
                  className={className + ' cr-w-36'}
                >
                  <MoreButton id={dataItem.id} MenuItems={[...extraButtons(dataItem as any)]} />
                </ListViewCell>
              );
            case 'icon':
              return (
                <ListViewCell
                  key={componentKey('td-data', column.param, dataItem.id)}
                  onClick={onClick}
                  className={className + ' cr-w-36 cr-align-middle '}
                >
                  <div className={'cr-text-center'}>
                    <RunStatusColors item={dataItem}>
                      {dataLoading.has(dataItem.id) ? <CircularProgress /> : <StreamlinesRun />}
                    </RunStatusColors>
                  </div>
                </ListViewCell>
              );
            case 'run_ts_tz':
              return (
                <ListViewCell
                  key={componentKey('td-data', column.param, dataItem.id)}
                  onClick={onClick}
                  className={className}
                >
                  {dataItem.run_status === 1000
                    ? 'Planned Run'
                    : formatDate(dataItem.run_ts_tz || dataItem.run_ts, 'shortDate')}
                </ListViewCell>
              );

            case 'is_next':
              return (
                <ListViewCell
                  key={componentKey('td-data', column.param, dataItem.id)}
                  onClick={onClick}
                  className={className}
                >
                  {isNextConfig ? <IsNextFlag name={'next-run'} id={dataItem.id} selected={isNextConfig} /> : null}
                </ListViewCell>
              );
            case 'average':
              return (
                <ListViewCell
                  key={componentKey('td-data', column.param, dataItem.id)}
                  onClick={onClick}
                  className={className}
                >
                  {dataItem.average?.['CdAXWnNB_xG3']?.[0] ? (
                    <div>{dataItem.average[cdaParam][0].toFixed(3)}</div>
                  ) : (
                    <>{null}</>
                  )}
                </ListViewCell>
              );
            case 'deltas':
              return (
                <ListViewCell
                  key={componentKey('td-data', column.param, dataItem.id)}
                  onClick={onClick}
                  className={className}
                >
                  {dataItem.deltas ? (
                    <div
                      className={`${dataItem.deltas('CdAXWnNB_xG3') <= 0 ? 'cr-font-bold cr-text-blue-500 ' : 'cr-text-red'}`}
                    >
                      {dataItem.deltas(cdaParam) > 0 ? '+' : ''}
                      {dataItem.deltas(cdaParam)?.toFixed(3)}
                    </div>
                  ) : null}
                </ListViewCell>
              );
            case 'run_number': {
              const item: any = dataItem;
              return (
                <ListViewCell
                  key={componentKey('td-data', column.param, dataItem.id)}
                  onClick={onClick}
                  rowSpan={2}
                  className={className}
                >
                  {item[column.param]}
                  <Notes item={dataItem} />
                </ListViewCell>
              );
            }
            default: {
              const item: any = dataItem;
              return (
                <ListViewCell
                  key={componentKey('td-data', column.param, dataItem.id)}
                  onClick={onClick}
                  className={className + ' ' + (column.param === 'description' ? columnHiddenMdClass : '')}
                >
                  {item[column.param]}
                </ListViewCell>
              );
            }
          }
        })}
      </ListViewRow>
      <ListViewRow>
        <ListViewCell colSpan={10} onClick={onClick} className={hasWarning ? className : ''}>
          <ListWarningsComponent isHidden={dataItem.hidden} listWarning={dataItem.listWarning} />
        </ListViewCell>
      </ListViewRow>
    </Fragment>
  );
}
